.loading {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffff;
    z-index: 2;
    cursor: pointer;
  }

  .loading-div{
    width: 200px;
    height: 100px;
    margin: 22% auto;
    margin-left: 50%;
    position: relative;
  }

  .loading span{
    width : 20px;
    height : 20px;
    margin: 0 5px;
    background-color: #0a0e2d;
    border-radius: 50%;
    display: inline-block;
    z-index: 10;
    animation-name: dots;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  .loading span:nth-child(2){
    background-color: #cccccc;
    animation-delay: 0.2s;
  }

  .loading span:nth-child(3){
    background-color: #000000;
    animation-delay: 0.4s;
  }

  @keyframes dots{
    50%{
      opacity: 0;
      transform: scale(0.7) translateY(10px)
    }
  }