.ag-theme-balham .ag-cell {
  border: 1px solid #ddd;
  border-bottom: 0;
}

.ag-theme-balham .ag-header {
  background-color: #2356a5;
  height: 60px !important;
  min-height: 60px !important;
}

.ag-header-cell-label {
  color: #fff;
}

.ag-theme-balham [class^="ag-"] {
  font-size: 16px;
}

.ag-header-row.ag-header-row-column {
  height: 58px !important;
}
.ag-unselectable {
  -webkit-user-select: text !important; /* Safari */
  -ms-user-select: text !important; /* IE 10 and IE 11 */
  user-select: text !important; /* Standard syntax */
}

/* .tracking-reset,
.css-dvatjy-MuiButtonBase-root-MuiButton-root {
  background-color: #e31a1a !important;
} */
