.MuiBox-root.css-x1q3ng {
    height: calc(80vh - 10px);
    min-height: calc(80vh - 10px);
}

.css-1e6b0rg {
    height: calc(80vh - 10px);
    min-height: calc(80vh - 10px);
}

.css-1snzrqz-MuiButtonBase-root-MuiButton-root {
    background: linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%) border-box !important;
    border: 0 !important;
}

.css-buwai7-MuiButtonBase-root-MuiButton-root {
    background: linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%) border-box !important;
    border: 0 !important;
}

.fc-col-header {
    background-image: linear-gradient(310deg, #2152ff, #21d4fd);
}

.fc-col-header th div a {
    color: #fff !important;
    font-weight: bold;
    line-height: 35px;
}

.fc .fc-daygrid-bg-harness {
    background-image: linear-gradient(310deg, #2152ff, #21d4fd);
    font-weight: bold;
}