div#Hazardous-Materials {
  width: 100% !important;
}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.css-1xslqe-MuiInputBase-root-MuiOutlinedInput-root {
  border-color: #4a5568;
  border-radius: 11px !important;
}

/* .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-s2ec2r-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  opacity: 1;
  transform: none;
  min-width: 750px;
  max-height: 224px;
  width: 400px;
  transition: opacity 289ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 192ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  top: 209px;
  left: 478px;
  transform-origin: 375px 0px;
  margin-top: 10px;
} */
