@import url(https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/plus-jakarta-display.min.css);
/* h1,h2,h3,h4,h5,h6,p,label{
    color:#344747 !important;
}
svg{
    color:#344747 !important;
}
svg{
    color:#344747 !important;
}
th, td, tr{
    color: inherit !important;
}
::placeholder{
    color:#344747 !important;
} */
/* svg{
    color:inherit !important;
} */

table.table-small {
    border: 1px solid rgb(204, 204, 204);
}

table.table-small tr td {
    padding-left: 5px;
}

table.table-small tr {
    border: 1px solid rgb(204, 204, 204);
}

.MuiBreadcrumbs-ol li:last-child a span {
    font-weight: bold;
    color: #000;
    opacity: 1;
}

.hideForonbording {
    margin-left: 0px !important;
}

.MuiButton-containedPrimary {
    color: #fff !important;
}

.subscription_card {
    position: relative;
    height: 50vh;
    overflow: hidden;
    margin: 16px;
    padding-top: 96px;
    padding-bottom: 160px;
    opacity: 1;
    background: linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253));
    color: rgb(52, 71, 103);
    border-radius: 1rem;
}

.subscription_card img {
    width: 105rem;
    position: absolute;
    top: 0px;
    opacity: 1;
    background: transparent;
    color: rgb(52, 71, 103);
}